<template>
	<CSidebar
		fixed
		:minimize="minimize"
		:show="show"
		size="sm"
		@update:show="value => $store.commit('set', ['sidebarShow', value])"
	>
		<CSidebarBrand class="d-md-down-none" :class="{ 'bg-black': !minimize }" to="/">
			<CIcon
				class="c-sidebar-brand-full"
				:src="logo"
				size="custom-size"
				:height="35"
				view-box="0 0 556 134"
			/>
			<CIcon
				class="c-sidebar-brand-minimized"
				:src="logoSmall"
				size="custom-size"
				:height="35"
				view-box="0 0 110 134"
			/>
		</CSidebarBrand>

		<CRenderFunction flat :content-to-render="computedSidebar" />
		<CSidebarMinimizer
			class="d-md-down-none"
			@click.native="$store.commit('set', ['sidebarMinimize', !minimize])"
		/>
	</CSidebar>
</template>

<script>
import i18n from "@/shared/plugins/i18n"
import logo from "@/assets/img/logo.svg"
import logoSmall from "@/assets/img/logo_small.png"
import { USER_ROLE } from "../plugins/constants"
import { createNamespacedHelpers } from "vuex"
const { mapState } = createNamespacedHelpers("authentication")

const allItems = [
	{
		_name: "CSidebarNavItem",
		name: i18n.t("Sidebar.FormManagement"),
		to: "/forms",
		roles: [USER_ROLE.SUPER_ADMIN, USER_ROLE.QA, USER_ROLE.ADMIN],
		icon: "cil-file",
	},
	{
		_name: "CSidebarNavItem",
		name: i18n.t("Sidebar.OrderManagement"),
		to: "/orders",
		roles: [USER_ROLE.SUPER_ADMIN, USER_ROLE.ADMIN, USER_ROLE.QA],
		icon: "cil-cart",
	},
	{
		_name: "CSidebarNavItem",
		name: i18n.t("Sidebar.CustomerManagement"),
		to: "/customers",
		roles: [USER_ROLE.SUPER_ADMIN, USER_ROLE.ADMIN],
		icon: "cil-address-book",
	},
	{
		_name: "CSidebarNavItem",
		name: i18n.t("Sidebar.TypeOrderManagement"),
		to: "/type-order",
		roles: [USER_ROLE.SUPER_ADMIN, USER_ROLE.ADMIN],
		icon: "cil-library",
	},
	{
		_name: "CSidebarNavItem",
		name: i18n.t("Sidebar.EmailManagement"),
		to: "/emails",
		roles: [USER_ROLE.SUPER_ADMIN, USER_ROLE.ADMIN],
		icon: "cil-envelope-closed",
	},
	{
		_name: "CSidebarNavItem",
		name: i18n.t("Sidebar.EditorManagement"),
		to: "/editors",
		roles: [USER_ROLE.SUPER_ADMIN, USER_ROLE.ADMIN],
		icon: "cil-group",
	},
	{
		_name: "CSidebarNavItem",
		name: i18n.t("Sidebar.PaysheetManagement"),
		to: "/paysheet",
		roles: [USER_ROLE.SUPER_ADMIN, USER_ROLE.ADMIN],
		icon: "cil-color-border",
	},
	{
		_name: "CSidebarNavItem",
		name: i18n.t("Sidebar.CustomerBillManagement"),
		to: "/customer-bill",
		roles: [USER_ROLE.SUPER_ADMIN],
		icon: "cil-money",
	},
	{
		_name: "CSidebarNavItem",
		name: i18n.t("Sidebar.EditorPayrollManagement"),
		to: "/editor-payroll",
		roles: [USER_ROLE.SUPER_ADMIN, USER_ROLE.ADMIN],
		icon: "cil-spreadsheet",
	},
	{
		_name: "CSidebarNavItem",
		name: i18n.t("Sidebar.TransactionManagement"),
		to: "/transaction",
		roles: [USER_ROLE.SUPER_ADMIN, USER_ROLE.ADMIN],
		icon: "cib-cc-paypal",
	},
	{
		_name: "CSidebarNavItem",
		name: i18n.t("Sidebar.UserManagement"),
		to: "/users",
		roles: [USER_ROLE.SUPER_ADMIN, USER_ROLE.ADMIN],
		icon: "cil-user",
	},
	{
		_name: "CSidebarNavItem",
		name: i18n.t("Sidebar.UserAppManagement"),
		to: "/user-app",
		roles: [USER_ROLE.SUPER_ADMIN, USER_ROLE.ADMIN],
		icon: "cil-contact",
	},
]

export default {
	name: "TheSidebar",
	data() {
		return {
			logo: logo,
			logoSmall: logoSmall,
		}
	},
	computed: {
		...mapState(["currentUser"]),
		show() {
			return this.$store.state.sidebarShow
		},
		minimize() {
			return this.$store.state.sidebarMinimize
		},
		computedSidebar() {
			return [
				{
					_name: "CSidebarNav",
					_children: this.renderSidebarByRole(),
				},
			]
		},
	},
	watch: {
		minimize(val) {
			if (!val) {
				const timeout = setTimeout(() => {
					this.logo = logo
					clearTimeout(timeout)
				}, 100)
			} else this.logo = logoSmall
		},
	},
	methods: {
		renderSidebarByRole() {
			if (this.currentUser && this.currentUser.role) {
				const navs = allItems.filter(item => {
					return item.roles.includes(this.currentUser.role)
				})
				return this.checkActiveNav(navs)
			}

			return this.checkActiveNav(allItems)
		},

		checkActiveNav(items) {
			try {
				items.forEach(nav => {
					nav["addLinkClasses"] = ""
					if (nav.to && this.$route.path.includes(nav.to)) {
						nav["addLinkClasses"] = "c-active"
					} else if (nav.items && nav.items.length) {
						this.checkActiveNav(nav.items)
					}
				})
				return items
			} catch (error) {}
		},
	},
}
</script>
<style lang="scss">
@import "@/assets/scss/variables.scss";
.bg-black {
	background: #000 !important;
}
.c-active {
	a {
		background: rgba(255, 255, 255, 0.05) !important;
	}
}

.c-sidebar-nav-icon {
	height: 1.5rem;
	color: $black !important;
}

.c-sidebar-nav-link {
	&:hover {
		.c-sidebar-nav-icon {
			color: $base-color !important;
		}
	}
}
.c-sidebar-nav-link.c-active {
	.c-sidebar-nav-icon {
		color: $base-color !important;
	}
}
.c-sidebar-minimizer {
	width: 190px;
	background-color: $white !important;
}

@media (min-width: 992px) {
	.c-sidebar-minimized .c-sidebar-minimizer {
		width: 50px;
	}
}

.c-sidebar .c-sidebar-minimizer:hover::before {
	background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='%238a93a2' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E");
}

.c-sidebar.c-sidebar-minimized .c-sidebar-nav-item:hover > .c-sidebar-nav-link {
	background: $white;
}

.c-sidebar-minimized .c-sidebar-nav-link:hover {
	width: 192px;
}
</style>
